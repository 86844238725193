
import Cookies from 'js-cookie'
import Vue from 'vue'
import $ from 'jquery'

Vue.mixin({
	methods: {
		j: $,
		post: (url, data, callback) => {
			$.post(url, data, callback)
		},
		get: (url, data, callback) => {
			$.get(url, data, callback)
		},
		ajax: (settings, callback) => {
			$.ajax(settings).done(callback)
		},
		formatDate: date => {
			var monthNames = [
			"January", "February", "March",
			"April", "May", "June", "July",
			"August", "September", "October",
			"November", "December"
			]

			var day = date.getDate()
			var monthIndex = date.getMonth()
			var year = date.getFullYear()

			return monthNames[monthIndex] + ' ' + day + ', ' + year
		}
	}
})

Vue.component('options', {
	data () {
		return {
			admin_ajax: window.data.admin_ajax,
			myLocation: window.data.myLocation,
			waitTime: -1,
			reservation: {
				phoneNumber: '',
				name: '',
				lastname: '',
				numberInParty: 1,
				visitNotes: ''
			},
			uniqueId: false,
			posting: false,
			errors: false
		}
	},

	created () {

		// console.log(this.myLocation.hours)
		// if (this.myLocation.hours == undefined) {
		// 	$.post( this.admin_ajax, {
		// 		action: 'get_location_hours',
		// 		location_id: this.myLocation.id
		// 	}, res => {
		// 		this.myLocation.hours = res
		// 		this.$forceUpdate()
		// 		this.setLocation(this.myLocation)
		// 		console.log(res)
		// 	})
		// }

	},

	methods: {

		waitlist () {

			if (this.waitTime < 0 && this.myLocation.tableup_id) {

				let settings = {
					"async": true,
					"crossDomain": true,
					"url": "https://production.tableup.com/api/v1/waitlist/currentWaitTime?restaurantId=" + this.myLocation.tableup_id,
					"method": "GET",
					"headers": {
						"Content-Type": "application/json",
						"Api-Key": "afe3ede0-40e6-40b5-85df-2bc48cb451b6",
						"Accept": "*/*",
						"Cache-Control": "no-cache",
						"cache-control": "no-cache"
					}
				}

				this.ajax(settings, res => {
					this.waitTime = res.restaurant.estimatedWait
				})

			}

		},

		reserve: function (e) {

			e.preventDefault()

			if (this.myLocation.tableup_id &&
					this.reservation.phoneNumber.length > 9 &&
					this.reservation.name.length > 0 &&
					this.reservation.lastname.length > 0 &&
					this.reservation.numberInParty > 0 &&
					!this.posting) {

				this.posting = true

				let settings = {
					"async": true,
					"crossDomain": true,
					"url": "https://production.tableup.com/api/v1/waitlist/addParty",
					"method": "POST",
					"headers": {
						"Content-Type": "application/json",
						"Api-Key": "afe3ede0-40e6-40b5-85df-2bc48cb451b6",
					}
				}

				let data = {
					"restaurantId": this.myLocation.tableup_id,
					"phoneNumber": this.reservation.phoneNumber,
					"name": this.reservation.name,
					"lastname": this.reservation.lastname,
					"numberInParty": Number(this.reservation.numberInParty)
				}

				if (this.reservation.visitNotes.length > 0) {
					data.visitNotes = this.reservation.visitNotes
				}

				settings.data = JSON.stringify(data)

				this.ajax(settings, res => {
					console.log(res)
					this.posting = false
					if (res.uniqueId) {
						this.uniqueId = res.uniqueId
					}
				})

			}

		},

    setLocation(loc) {
      this.myLocation.id = loc.id;
      this.myLocation.link = loc.link;
      this.myLocation.name = loc.name;
      this.myLocation.tableup_id = loc.tableup_id;
	  this.myLocation.location_catering_menu = loc.location_catering_menu;
	  this.myLocation.location_holiday_catering_menu = loc.location_holiday_catering_menu
      this.myLocation.downloadable_menu_link = loc.downloadable_menu_link;
      this.myLocation.downloadable_gluten_free_menu =
        loc.downloadable_gluten_free_menu;
      this.myLocation.downloadable_location_catering_menu =
        loc.downloadable_location_catering_menu;

      Cookies.set("STYXKEY_metrodiner", {
        myLocation: this.myLocation
      });
    }

	}
})

new Vue({
	el: '#bottom-menu'
})
